/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState, useReducer, useMemo } from "react";
import {
  Button,
  Typography,
  AppBar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
  FormHelperText
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { push } from "connected-react-router";
import {
  LoanType,
  BrowseFileButton,
  WarningModal,
  LoanStage,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { RootState } from "../stores/rootReducer";
import {
  getLoanType,
  //saveLoanDetailsForm,
  getLoanId,
  downloadFile,
  getPreAssignedURL,
  uploadAWSFile,
  createLoanUsingExcel,
  validateExcel,
  resetAllStores,
  convertLoan,
  getOriginatorList
} from "./create-loan.action";
import excelIcon from "../images/excel-doc.svg";
import proceed from "../images/icons-dark-proceed.svg";
import remove from "../images/icons-dark-remove.svg";
import loanImgRight from "../images/createloan/Illustration.svg";
import loanImgLeft from "../images/createloan/illustrationRight.svg";
import UploadExcel from "../images/createloan/upload.svg";
import ManualCreateLoan from "../images/createloan/type.svg";
import EvaluateLoan from "../images/evaluate_loan.svg";
import downloadImg from "../images/createloan/download@2x.png";
import downloadDarkIcon from "../images/createloan/downloadDarkIcon.svg";
import { useStyles } from "./common-style/CreateLoanType.style";
import {
  getExcelDataInSession,
  isLATUser,
  // isRole,
  setBackToOriginalOrgId
} from "../utils/AccessManagement";
import { LoanIdListInSession } from "./excel-create-loan/LoanIdList";
// import { resetExcelUploadReducer } from "../stores/ExcelUpload/excel-upload.action";
// import { resetRetryRequestReducer } from "../stores/RetryRequest/retry-request.action";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { getCookie, setCookie } from "../utils/cookies";
import { DragAndDrop } from "./DragAndDrop";
import { ResolutionNotSupported } from "../ui-components/ResolutionNotsupported";
import { RadioButtonGroup } from "../ui-components/RadioButtonGroup";
import { showDuplicateDetals } from "./handle-duplicate-loans/DuplicateLoansFunctionality";
import { usePolling } from "../custom-hooks/usePolling";
import { getConfig, getLoanType as getLoanTypes } from "../config/config";
import { resetRetryRequestReducer } from "../stores/RetryRequest/retry-request.reducer";
import { resetExcelUploadReducer } from "../stores/ExcelUpload/excel-upload.reducer";
import { saveLoanDetailsForm } from "./create-loan.reducer";
import { bucketName, TABLE_FUNDING } from "../utils/constants";
import { ToggleGroup } from "../ui-components/ToggleGroup";
import { getOrgSpecificLoanTypes } from "../network/apiService";
import { ObjectType } from "../masterView/common";

const defaultcloudFilePathBucket = {
  BucketName: bucketName,
  Bucketkey: ""
};

interface DownloanIconProps {
  isDark?: boolean;
}

export const disableExcel = (
  loanStage: string,
  loanType?: string
) => {
  if (!loanStage || !loanType) return true;
  if (loanStage === "Guideline Evaluation") {
    // if (window.location.hostname === "www.toorakcapital.info") {
    //   return true;
    // }
    return loanStage === "Guideline Evaluation" && loanType === "Bridge Loan";
  } else if (loanStage === "Toorval") {
    return loanType.toLowerCase() === "30 year loan";
  }
  return false;
};
export const DownloadIcon = (props: DownloanIconProps) => {
  const { isDark } = props;
  if (isDark) {
    return (
      <>
        <img
          src={downloadDarkIcon}
          alt="download"
          style={{ height: "16px", width: "16px", color: "#32325d" }}
        />
      </>
    );
  }
  return (
    <>
      <img
        src={downloadImg}
        alt="download"
        style={{ height: "16px", width: "16px", color: "#32325d" }}
      />
    </>
  );
};

export const UploadExcelIcon = () => {
  return (
    <>
      <img src={UploadExcel} alt="upload_excel" />
    </>
  );
};

interface IconProps {
  loanStage: string;
}

export const ManualCreateLoanIcon = (props: IconProps) => {
  const { loanStage } = props;
  return (
    <>
      {loanStage === "Guideline Evaluation" ? (
        <img src={EvaluateLoan} alt="evaluate_loan" />
      ) : (
        <img src={ManualCreateLoan} alt="manual_create_loan" />
      )}
    </>
  );
};

export const CreateLoanType = () => {
  const dispatch = useDispatch();
  const navigateUrl = useNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const loans = useSelector<RootState, LoanType[]>(
    (state) => state.createLoanStore.loanTypes
  );
  const [orgSpecificLoanTypes, setOrgSpecificLoanTypes] = useState<Map<string, ObjectType>>(new Map());
  const [orgType, setOrgType] = useState<string | null>(null);
  const [loanSelectionError, setLoanSelectionError] = useState<{
    loanTypeError: boolean;
    loanStageError: boolean;
    isOrgSelected: boolean;
  }>({ loanTypeError: false, loanStageError: false, isOrgSelected: false });

  const [loanType, setLoanType] = useState<LoanType | null>(null);

  const [cloudFilePathBucket, setcloudFilePathBucket] = useState<{
    BucketName: string;
    Bucketkey: string;
  } | null>(null);

  const [docUploaded, setDocUploaded] = useState<boolean>(false);
  const [duplicateLoanInExcel, setduplicateLoanInExcel] =
    useState<boolean>(false);
  const [sessionStorageData, setSessionStorageData] = useState<{
    excelLoanCreated: boolean;
    loanIdList: any;
    fileName: "string";
  } | null>(null);
  const [uploadedDocName, setUploadedDocName] = useState<string | null>(null);
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorInDoc, setErrorInDoc] = useState<string | null>(null);
  const [conflictError, setConflictError] = useState<{ loanId: string } | null>(
    null
  );
  const [duplicateLoanErrorInDoc, setDuplicateLoanErrorInDoc] =
    useState<boolean>(false);
  const [loanStage, setLoanStage] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { DataEntry, ExcelError, ExcelSuccess } = LoanStatusEnum;
  const [showWarning, setShowWarning] = useState<{
    isOpen: boolean;
    header?: string;
    body?: string;
    primaryButtonName?: string;
    secondaryButtonRequired?: boolean;
  }>({
    isOpen: false
  });
  const [originatorList, setOriginatorList] = useState<[]>();
  const [currentOriginatorId, setCurrentOriginatorId] = useState("");
  const isLoading = useMemo(
    () => loading || uploading || docUploaded,
    [loading, uploading, docUploaded]
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [downloadTemplate, setDownloadTemplate] = useState("");
  const { startPolling } = usePolling();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loanTypeOptions: ObjectType[] = useMemo(() => {
    return loans?.filter(it => it.loanTypeName !== "2+1 Loan").map((loan: LoanType) => {
      return { disabled: !orgSpecificLoanTypes.has(loan?.loanTypeName), value: loan?.loanTypeName, label: loan?.loanTypeDescription?.toUpperCase() }
    });
  }, [loans, orgSpecificLoanTypes]);

  const loanStageValues = useMemo(
    () => [
      {
        value: "Pre-Close Loan",
        label: "Pre-Close Loan",
        customClass: loanSelectionError.loanStageError ? "radio-alert" : "",
        isDisabled: isLoading
      },
      {
        value: "Post-Close Loan",
        label: "Post-Close Loan",
        customClass: loanSelectionError.loanStageError ? "radio-alert" : "",
        isDisabled: isLoading || orgType === TABLE_FUNDING
      },
      {
        value: "Guideline Evaluation",
        label: "Guideline Evaluation",
        customClass: loanSelectionError.loanStageError ? "radio-alert" : "",
        isDisabled: isLoading
      }
      // {
      //   value: "Toorval",
      //   label: "Toorval",
      //   customClass: loanSelectionError.loanStageError ? "radio-alert" : "",
      //   isDisabled:
      //     loanType?.loanTypeDescription?.toLowerCase() === "investor dscr" ||
      //     isLoading ||
      //     tableFundingStatus === "Yes",
      //   isHidden: !isRole("CREATE_TOORVAL")
      // }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loanSelectionError, isLoading, loanType, orgType]
  );

  const getLoanStage = (value: string) => {
    switch (value) {
      case "Pre-Close Loan":
        return LoanStage.pre;
      case "Post-Close Loan":
        return LoanStage.post;
      case "Guideline Evaluation":
        return LoanStage.fes;
      case "Toorval":
        return LoanStage.tv;
      default:
        return LoanStage.pre;
    }
  };
  // Useful to set back the lat org id. Should be removed and not to be handled using cookies through out the loan.
  function setCurrentOrgId() {
    const isCurrentOrgIdAvailable = getCookie("current_org_id");
    const currentOrgId = getCookie("org_id");
    if (!isCurrentOrgIdAvailable) {
      setCookie("current_org_id", currentOrgId);
    }
  }
  const onOriginatorValueChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCurrentOriginatorId(event.target.value as string);
    setCurrentOrgId();
    setCookie("org_id", event.target.value as string);
  };

  useEffect(() => {
    if (!isLATUser()) {
      const orgId = getCookie("org_id") ?? "";
      setCurrentOriginatorId(orgId);
    }
  }, []);

  useEffect(() => {
    if (!currentOriginatorId) return;
    getOrgSpecificLoanTypes(currentOriginatorId).then((res: any) => {
      setOrgSpecificLoanTypes(new Map(res?.data?.loanTypes?.map((it: ObjectType) => [it.loanTypeName, it])));
      setOrgType(res?.data?.fundingType);
    })
      .catch((error: any) => {
        console.log(error)
      });
      setLoanType(null);
      setLoanStage("");
  }, [currentOriginatorId, dispatch]);

  useEffect(() => {
    dispatch(getLoanType());
    setBackToOriginalOrgId();
    // get the current user mapped org user list on LAT login
    if (isLATUser()) {
      const userList = getOriginatorList();
      userList
        .then((resp: any) => {
          setOriginatorList(resp.data);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    }
  }, [dispatch]);

  const [excelLoanInfo, setexcelLoanInfo] = useState({});
  // let excelLoanInfo: any = {};
  const handleLoanTypeToggle = (e: any, value: string) => {
    setLoanType(loans.filter(item => item.loanTypeName === value)[0]);
  }
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoanStage((event.target as HTMLInputElement).value);
  };

  // returns true if both loan type and loan stage are selected. updates error state if any one of them is missing
  const validateSelections = () => {
    const orgSelected = isLATUser() ? currentOriginatorId : true;
    if (loanStage && loanType && orgSelected) {
      if (
        loanSelectionError.loanTypeError ||
        loanSelectionError.loanStageError ||
        loanSelectionError.isOrgSelected
      ) {
        setLoanSelectionError({
          loanTypeError: !loanType,
          loanStageError: !loanStage,
          isOrgSelected: !currentOriginatorId
        });
      }
      return true;
    }
    setLoanSelectionError({
      loanTypeError: !loanType,
      loanStageError: !loanStage,
      isOrgSelected: !currentOriginatorId
    });
    return false;
  };

  const createLoanId = (loanType: LoanType) => {
    dispatch(showLoader());
    setLoading(true);
    dispatch(resetAllStores());
    const loanstype = loans.filter(
      (item) => item.loanTypeId === loanType.loanTypeId
    )[0];
    const loanStageToPass: LoanStage = getLoanStage(loanStage);
    getLoanId(
      loanType.loanTypeId,
      loanstype.loanTypeName,
      loanStageToPass,
      currentOriginatorId
    )
      .then((res) => {
        const loanId = res;
        dispatch(
          saveLoanDetailsForm({
            loanType
          })
        );
        const loanTypePath = loanType.loanTypeDescription.replace(/ /g, "");
        navigateUrl(
          `/loan/createLoan/${loanTypePath}/${loanStageToPass}/${loanId}`
        );

        dispatch(hideLoader());
        setLoading(false);
      })
      .catch((e) => {
        dispatch(hideLoader());
        setLoading(false);
      });
  };

  function handleUpload(files: any) {
    setUploading(true);
    const fileChosen = files[0];
    const isValidFile = fileChosen.name.split(".").pop() === "xlsx";
    if (isValidFile) {
      const body = {
        BucketName: `${bucketName}`,
        Bucketkey: `S3_Bucket/${getCookie(
          "org_id"
        )}/excelUpload/${new Date().getTime()}/${files[0].name}`
      };
      getPreAssignedURL(body, fileChosen.type)
        .then(async (awsUrl: any) => {
          uploadAWSFile(fileChosen, {}, awsUrl)
            .then((response) => {
              setUploadedDocName(fileChosen.name);
              setDocUploaded(true);
              setcloudFilePathBucket(body);
              setUploading(false);
            })
            .catch((e) => {
              console.log(e);
              setUploading(false);
              setErrorInDoc("Sorry, Something went wrong! Please try again.");
            });
        })
        .catch((e) => {
          console.log(e);
          setUploading(false);
        });
    } else {
      setUploading(false);
      setShowWarning({
        isOpen: true,
        header: "Warning",
        body:
          fileChosen.name.split(".").pop().toLowerCase() === "xls"
            ? "xls file format is not supported. Please save the file in .xlsx format and try again"
            : "Invalid file format. Please select file types xlsx.",
        primaryButtonName: "Ok"
      });
    }
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        handleUpload(action.files);
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };
  const [data, dispatchh] = useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: []
  });

  /** If the loans are created using excel upload flow,then the data required for next flows are available in session storage.
   * once component is rendered, check if the excel upload flow was taken by the user and update the state variables accordingly.
   */
  useEffect(() => {
    const excelUploadData = getExcelDataInSession();
    if (excelUploadData) {
      try {
        setSessionStorageData(excelUploadData);
        setDocUploaded(true);
        const { loanStage, fileName, currentOriginatorId } =
          excelUploadData;
        const loanStageToPass: string =
          loanStage === LoanStage.pre ? "Pre-Close Loan" : "Post-Close Loan";
        setLoanStage(loanStageToPass);
        setUploadedDocName(fileName);
        if (currentOriginatorId) {
          setCurrentOriginatorId(currentOriginatorId);
          setCurrentOrgId();
          setCookie("org_id", currentOriginatorId);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    let defaultLoanType;
    const excelUploadData = getExcelDataInSession();
    if (excelUploadData) {
      defaultLoanType = loans.filter(
        (item) =>
          item.loanTypeDescription.replace(/ /g, "") ===
          excelUploadData.loanTypePath
      )[0];
    }
    if (defaultLoanType) {
      setLoanType(defaultLoanType);
    }
  }, [loans]);

  useEffect(() => {
    if (
      loanSelectionError.loanStageError ||
      loanSelectionError.loanTypeError ||
      loanSelectionError.isOrgSelected
    ) {
      validateSelections();
    }
    // eslint-disable-next-line
  }, [loanType, loanStage, currentOriginatorId]);

  const downloadTemplateData: any = [
    {
      id: "bridgePreClose",
      label: "Bridge Pre-Close",
      loanType: getLoanTypes[0].loanCreationName,
      loanStage: LoanStage.pre
    },
    {
      id: "bridgePostClose",
      label: "Bridge Post-Close",
      loanType: getLoanTypes[0].loanCreationName,
      loanStage: LoanStage.post
    },
    {
      id: "investorDscrGuidlineEvaluation",
      label: "Investor DSCR Guideline Evaluation",
      loanType: getLoanTypes[1].loanCreationName,
      loanStage: LoanStage.fes
    },
    {
      id: "investorDscrPreClose",
      label: "Investor DSCR Pre-Close",
      loanType: getLoanTypes[1].loanCreationName,
      loanStage: LoanStage.pre
    },
    {
      id: "investorDscrPostClose",
      label: "Investor DSCR Post-Close",
      loanType: getLoanTypes[1].loanCreationName,
      loanStage: LoanStage.post
    }
    // {
    //   id: "bridgeTooval",
    //   label: "Bridge Toorval",
    //   loanType: getLoanTypes[3].loanCreationName,
    //   loanStage: LoanStage.tv
    // }
  ];

  // const onDownloadTemplateValueChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setDownloadTemplate(event.target.value as string);
  // };

  /** Download excel template based on loan type. */
  const handleDownload = (downloadLoanType: string) => {
    // if (validateSelections()) {
    const body = {
      Bucketkey: `loanCreation/template/${downloadLoanType}`
    };

    downloadFile(body, `${downloadLoanType}.xlsx`)
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
    // }
  };
  function removeUploadedExcel() {
    dispatch(resetExcelUploadReducer());
    dispatch(resetRetryRequestReducer());
    setDocUploaded(false);
    setUploadedDocName(null);
    setSessionStorageData(null);
    setErrorInDoc(null);
    setConflictError(null);
    setDuplicateLoanErrorInDoc(false);
    sessionStorage.removeItem("excelUpload");
  }
  function redirectToLoanPage(loanIdList: any, submitted?: boolean) {
    if (loanType?.loanTypeName) {
      const loanTypePath = loanType.loanTypeDescription.replace(/ /g, "");
      const loanIdLists = Object.keys(loanIdList);
      const loanStageToPass: LoanStage = getLoanStage(loanStage);
      const urlPath = `/loan/createLoan/${loanTypePath}/${loanStageToPass}`;
      if (submitted) {
        let firstDataEntryLoan: string | null = null;
        let initialReviewLoan: string | null = null;
        let dueDiligenceReviewLoan: string | null = null;
        Object.values(loanIdList).forEach((item: any) => {
          if (item.loanStage === LoanStatusEnum.InitialReview) {
            if (!initialReviewLoan) {
              initialReviewLoan = item.loanId;
            }
          } else if (item.loanStage === LoanStatusEnum.DueDiligenceReview) {
            if (!dueDiligenceReviewLoan) {
              dueDiligenceReviewLoan = item.loanId;
            }
          } else if (!firstDataEntryLoan) {
            firstDataEntryLoan = item.loanId;
          }
          dispatch(resetAllStores());
          if (firstDataEntryLoan) {
            navigate(`${urlPath}/${firstDataEntryLoan}`);
          } else if (initialReviewLoan) {
            navigate(`${urlPath}/${initialReviewLoan}`);
          } else {
            navigate(`${urlPath}/${dueDiligenceReviewLoan}`);
          }
        });
      } else {
        dispatch(resetAllStores());
        // delay so that redux can be clean
        setTimeout(() => {
          navigate(`${urlPath}/${loanIdLists[0]}`);
        }, 1000);
      }
    }
  }

  function showErrorPopupOnPolling(
    duplicateProperties: any[],
    topic: string,
    proceedFun: any
  ) {
    let duplicateLoanIds = "";
    switch (topic) {
      case "CONVERT":
        dispatch(showLoader());
        setOpenWarning({
          isOpen: true,
          handleOptional: () => {
            setOpenWarning(null);
          },
          header: "Duplicate Loan/Loan Conversion",
          primaryBtnName: "Ok",
          body: (
            <>
              <Grid container direction="column">
                <Grid item xs={12} style={{ padding: 20 }}>
                  <div>
                    There are existing loans in the system with the same
                    property address and originator loan id in the previous
                    stage that are submitted.
                  </div>
                  <br />
                  <div>{showDuplicateDetals(duplicateProperties)}</div>
                  <br />
                  {/* <div>
                    The existing loans will be converted and merged with the
                    current loan. In case of multiple loans, the latest one to
                    be considered. Do you want to proceed?
                  </div> */}
                  <div>Please reach out to your Toorak LAT representative.</div>
                </Grid>
              </Grid>
            </>
          ),
          handleClose: () => {
            setOpenWarning(null);
          }
        });
        dispatch(hideLoader(true));

        break;
      case "AND":
      case "OR": {
        // for 'OR' type
        duplicateProperties.forEach((element: any) => {
          if (duplicateLoanIds) {
            duplicateLoanIds = `${duplicateLoanIds}, ${element.loanId}`;
          } else {
            duplicateLoanIds = element.loanId;
          }
          // appear converted loans only once
          duplicateLoanIds = Array.from(
            new Set(duplicateLoanIds.split(", "))
          ).join(", ");
          setOpenWarning({
            isOpen: true,
            handleClose: () => {
              setOpenWarning(null);
            },
            header: "Duplicate Loan",
            body: `There is a loan with the loan ID ${duplicateLoanIds} already submitted either for the added properties or with the same Originator Loan ID. Please contact Toorak Team for further assistance.`
          });
        });
      }
    }
  }

  const onPollSuccess = (resp: any, loanStageToPass: LoanStage) => {
    if (resp.data.status === "Created") {
      return true; // Continue polling
    }
    if (resp.data.status === "Success" || resp.data.status === "Error") {
      const { errors, duplicates } = resp.data?.outputContext;
      let loanDetails = resp.data?.outputContext?.loanDetails;
      /**
       * In FES loan, as per 4767, there can be only 1 loan in an excel. In futture if it becomes multiple loan, before removing this code consider 2 things
       * 1)Does back end support multiple loan submit for fes loans.
       * 2)Change loanIdList to support fes.
       */
      if (loanStageToPass === LoanStage.fes && loanDetails?.length > 1) {
        loanDetails = loanDetails[0];
      }
      /* fix me: multiple loan and error handling */
      if (loanStageToPass === "TOORVAL") {
        loanDetails = resp.data?.outputContext;
        if (loanDetails) {
          dispatch(hideLoader());
          setTimeout(() => {
            const urlPath = `/loan/createLoan/BridgeLoan/${loanStageToPass}`;
            navigate(`${urlPath}/${loanDetails[0].toorakLoanID}`);
          }, 1000);
        }
      } else if (loanDetails?.length > 0) {
        setexcelLoanInfo(loanDetails);
        // having both loans & duplicate means duplicate loans belong to OR category. So, Just show warning and proceed
        if (duplicates?.length > 0) {
          const duplicateLoanInfo = duplicates[0].loanPropertyDetails;
          const orgId = getCookie("org_id");
          const duplicatesLoansArray = duplicateLoanInfo.filter(
            (property: any) => {
              return (
                ![DataEntry, ExcelError, ExcelSuccess].includes(
                  property.loanState
                ) && orgId === property.originatorPartyId
              );
            }
          );
          let duplicateLoanIds: string = "";
          if (duplicatesLoansArray.length > 0) {
            duplicatesLoansArray.forEach((element: any) => {
              if (duplicateLoanIds) {
                duplicateLoanIds = `${duplicateLoanIds}, ${element.loanId}`;
              } else {
                duplicateLoanIds = element.loanId;
              }
              // appear converted loans only once
              duplicateLoanIds = Array.from(
                new Set(duplicateLoanIds.split(","))
              ).join(", ");
              setduplicateLoanInExcel(true);
              setShowWarning({
                isOpen: true,
                header: "Duplicate Loan",
                body: `There is a loan with the loan ID ${duplicateLoanIds} already submitted either for the added properties or with the same Originator Loan ID.`,
                primaryButtonName: "OK",
                secondaryButtonRequired: false
              });
            });
          } else {
            proceedWithExcelData(loanDetails);
          }
        } else {
          proceedWithExcelData(loanDetails);
        }
      } else if (errors?.length > 0) {
        const body = typeof errors === "string" ? errors : errors[0];
        setShowWarning({
          isOpen: true,
          header: "Warning",
          body,
          primaryButtonName: "OK",
          secondaryButtonRequired: false
        });
        setDuplicateLoanErrorInDoc(true);
        setErrorInDoc("Failed to create loan for this excel.");
      } else if (!loanDetails?.length && duplicates?.length > 0) {
        // Loans are not created. And duplicate loans belong to either AND category or CONVERT category
        let duplicateObject = resp.data.duplResultsDetails;
        if (!duplicateObject)
          duplicateObject = resp.data.outputContext.duplResultsDetails;
        const { metaData, duplResultsDetails } = duplicateObject;
        if (metaData?.duplResultsType === "CONVERT") {
          // show warning pop up with duplicate loan details.
          // On proceed create other loans and covert duplicate loans
          // show convrted loans in bundle after creation
          dispatch(hideLoader());
          const proceedFun = async () => {
            try {
              dispatch(showLoader());
              duplResultsDetails?.convertArray.map(async (convLoan: any) => {
                const loanStagetoConvert: LoanStage = getLoanStage(loanStage);
                // immporting docs by default
                await convertLoan(
                  convLoan.loanId,
                  convLoan.loanStage,
                  loanStagetoConvert,
                  true
                );
                // dispatch(hideLoader());
              });
              // create new loans & copy converted loan Id
              const resp = await createLoanUsingExcel(
                loanType?.loanTypeName || "",
                loanStageToPass,
                cloudFilePathBucket || defaultcloudFilePathBucket,
                duplResultsDetails?.convertArray
              );
              const Ids: any[] = duplResultsDetails?.convertArray.map(
                (item: any) => item.loanId
              );
              setexcelLoanInfo(resp.data.loanDetails);
              proceedWithExcelData(resp.data.loanDetails, Ids);
              dispatch(hideLoader());
            } catch (e) {
              console.log("error while converting", e);
              dispatch(hideLoader());
            }
          };
          showErrorPopupOnPolling(
            duplResultsDetails?.convertArray,
            "CONVERT",
            proceedFun
          );
        }
      } else {
        setErrorInDoc("Failed to create loan for this excel.");
      }
      dispatch(hideLoader());
      setUploading(false);
      return false;
    }
    return true;
  };

  const onPollTimeExceed = () => {
    dispatch(hideLoader());
    setShowWarning({
      isOpen: true,
      header: "Exceeded Time Limit",
      body: `Failed to finish exceuting before the established time`,
      primaryButtonName: "OK",
      secondaryButtonRequired: false
    });
  };

  function handleProceedClick() {
    if (
      !sessionStorageData?.excelLoanCreated &&
      loanType?.loanTypeName &&
      cloudFilePathBucket
    ) {
      const loanStageToPass: LoanStage = getLoanStage(loanStage);
      dispatch(showLoader());
      setUploading(true);
      validateExcel({
        originatorId: currentOriginatorId,
        loanState: "Data Entry",
        loanType: loanType?.loanTypeName,
        cloudFilePath: cloudFilePathBucket,
        loanStage: loanStageToPass
      })
        .then((resp) => {
          if (resp.data.status === "SUCCESS") {
            createLoanUsingExcel(
              loanType?.loanTypeName,
              loanStageToPass,
              cloudFilePathBucket,
              []
            )
              .then((resp) => {
                const { ticketId } = resp.data;
                const url = `${getConfig().apiUrl}/loans/tickets/${ticketId}`;
                startPolling({
                  url,
                  onSuccess: (res: any) => {
                    return onPollSuccess(res, loanStageToPass);
                  },
                  method: "GET",
                  onExceedingPollingWaitTime: () => {
                    onPollTimeExceed();
                  },
                  pollingWaitTime: 900000
                });
              })
              .catch((e) => {
                dispatch(hideLoader());
                setUploading(false);

                if (
                  e.response.status === 409 &&
                  loanStageToPass === LoanStage.tv
                ) {
                  const existingLoanID = e.response.data.message.toorakLoanID;
                  setConflictError({
                    loanId: existingLoanID
                  });
                } else {
                  setErrorInDoc(
                    "Something went wrong! Failed to create loan for this excel."
                  );
                }
              });
          } else {
            dispatch(hideLoader());
            setUploading(false);
            setErrorInDoc(
              (resp.data.errorDetails?.length &&
                resp.data.errorDetails[0].errorMessage) ||
                "Something went wrong! Failed to create loan for this excel."
            );
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(hideLoader());
          setUploading(false);
          setErrorInDoc(
            "Something went wrong! Failed to create loan for this excel."
          );
        });
    } else if (sessionStorageData?.excelLoanCreated && loanType?.loanTypeName) {
      dispatch(resetAllStores());
      const newData = {
        ...sessionStorageData,
        ...{ maintainExcelBundle: true }
      };
      sessionStorage.setItem("excelUpload", JSON.stringify(newData));
      redirectToLoanPage(sessionStorageData.loanIdList, true);
    }
  }

  function handleConfirm() {
    if (duplicateLoanInExcel) {
      proceedWithExcelData(excelLoanInfo);
      setduplicateLoanInExcel(false);
    } else {
      removeUploadedExcel();
    }
    setShowWarning({
      isOpen: false,
      header: "",
      body: "",
      primaryButtonName: "",
      secondaryButtonRequired: false
    });
  }

  function proceedWithExcelData(loanDetails: any, convertedLoanIds?: any[]) {
    dispatch(showLoader(true));
    const loanList: any[] = loanDetails;
    let loanIdListObj: {
      [key: string]: LoanIdListInSession;
    } = {};
    loanList.forEach((element) => {
      const hasPropertyLevelError = element.propertyData?.some(
        (property: any) =>
          property.hasErrors ||
          property.propertyId?.propertyLocationId?.locationValidationStatus !==
            "COMPLETE"
      );
      loanIdListObj = {
        ...loanIdListObj,
        ...{
          [element.loanId]: {
            loanId: element.loanId,
            primaryLoanId: element.loandata?.loanInfo?.primaryLoanId,
            excelError:
              element.loandata.loanState === "Excel Error" ||
              hasPropertyLevelError,
            loanStage: element.loandata.loanState
          }
        }
      };
    });
    let loanTypePath = "";
    if (loanType?.loanTypeName) {
      loanTypePath = loanType.loanTypeDescription.replace(/ /g, "");
    }
    const loanStageToPass: LoanStage = getLoanStage(loanStage);
    if (loanList.length > 1) {
      const dataToStoreInSession = {
        excelLoanCreated: true,
        loanStage: loanStageToPass,
        maintainExcelBundle: true,
        loanIdList: loanIdListObj,
        fileName: uploadedDocName,
        tradeId: loanList[0].loandata?.loanDetailId?.tradeId,
        loanTypePath,
        currentOriginatorId
      };
      sessionStorage.setItem(
        "excelUpload",
        JSON.stringify(dataToStoreInSession)
      );
    } else {
      sessionStorage.setItem("singleLoanExcelCreated", "true");
      removeUploadedExcel();
    }
    redirectToLoanPage(loanIdListObj);
    setTimeout(() => {
      dispatch(hideLoader(true));
    }, 1000);
  }

  function getFileType(fileName: any) {
    let fileType = "XLS";
    if (typeof fileName === "string") {
      const type = fileName.split(".").pop();
      if (type) {
        fileType = type.toUpperCase();
      }
    }
    return fileType;
  }

  const goBackToLoanListPage = () => {
    setBackToOriginalOrgId();
    navigate("/loans/list?tab=1");
  };

  const createLoan = () => {
    if (validateSelections() && loanType) {
      createLoanId(loanType);
    }
  };

  const handleDocRemove = () => {
    if (sessionStorageData?.excelLoanCreated && loanType?.loanTypeName) {
      setShowWarning({
        isOpen: true,
        header: "Warning",
        body: "Are you sure you want to remove this excel? You will lose the multiple loan group view. You can still select one loan from the list to see the loan details.",
        primaryButtonName: "Ok",
        secondaryButtonRequired: true
      });
    } else {
      removeUploadedExcel();
    }
  };

  const handleWarningClose = () => {
    if (!showWarning.secondaryButtonRequired) {
      setShowWarning({
        isOpen: false,
        header: "",
        body: "",
        primaryButtonName: "",
        secondaryButtonRequired: false
      });
      if (duplicateLoanInExcel) {
        proceedWithExcelData(excelLoanInfo);
        setduplicateLoanInExcel(false);
      }
    } else {
      handleConfirm();
    }
  };

  const handleWarningOptional = () => {
    if (!showWarning.secondaryButtonRequired) return;
    setShowWarning({
      isOpen: false,
      header: "",
      body: "",
      primaryButtonName: "",
      secondaryButtonRequired: false
    });
    setduplicateLoanInExcel(false);
  };

  const isManualCreateDisabled = useMemo(() => {
    const isLoanStageDisabled = loanStageValues.filter(
      ({ value }) => value === loanStage
    )[0]?.isDisabled;
    return isLoanStageDisabled || isLoading 
      ? true
      : false;
  }, [isLoading, loanStage, loanStageValues]);

  const isBulkUplodDisabled = useMemo(() => {
    const isLoanStageDisabled = loanStageValues.filter(
      ({ value }) => value === loanStage
    )[0]?.isDisabled;
    return isLoanStageDisabled ||
      uploading ||
      loading ||
      disableExcel(loanStage, loanType?.loanTypeName) 
      ? true
      : false;
  }, [
    uploading,
    loading,
    loanStage,
    loanType,
    loanStageValues
  ]);

  return (
    <div className={classes.rectangle}>
      {openWarning && (
        <WarningModal
          isOpen={openWarning.isOpen}
          handleClose={openWarning.handleClose}
          handleOptional={openWarning.handleOptional}
          primaryBtnName={openWarning.primaryBtnName}
          header={openWarning.header}
          body={openWarning.body}
        />
      )}
      <AppBar
        color="default"
        position="static"
        className={classes.createLoanHeader}
      >
        <Box
          className="button-typography-wrapper-left"
          style={{ padding: "4px 0px" }}
        >
          <Button
            variant="contained"
            data-testid="back-button"
            color="primary"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={goBackToLoanListPage}
          >
            Back
          </Button>
          <Typography>Loan Creation</Typography>
        </Box>
      </AppBar>
      <Container maxWidth="sm" className={classes.createLoanBodyWrapper}>
        <Box component="div" className={classes.formContainer}>
          <Grid container className={classes.headerContainer}>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={7}
              className={classes.loanInfoContainer}
            >
              {/* className={classes.loanInfo} */}
              <Typography className={classes.loanInfo}>
                Loan Information
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={6}
              lg={5}
              className={classes.downloadContainer}
            >
              <FormControl
                data-testid="originator-form-control"
                fullWidth
                error={loanSelectionError.isOrgSelected}
              >
                <Select
                  inputProps={{
                    "data-testid": "download-template"
                  }}
                  disabled={isLoading}
                  value={downloadTemplate}
                  variant="standard"
                  // onChange={onDownloadTemplateValueChange}
                  displayEmpty
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: 0
                    },
                    transformOrigin: {
                      vertical: -8,
                      horizontal: 45
                    }
                    //getContentAnchorEl: null
                    // variant="standard"
                  }}
                  classes={{
                    root: classes.originatorSelectRoot,
                    icon: classes.originatorIcon
                  }}
                >
                  <MenuItem
                    value=""
                    disabled
                    classes={{
                      root: classes.originatorListItem
                    }}
                  >
                    Download Template
                  </MenuItem>
                  {downloadTemplateData?.map((item: any, index: number) => (
                    <MenuItem
                      value={item?.label}
                      key={item?.id}
                      onClick={() => handleDownload(item?.loanType)}
                      classes={{
                        root: classes.originatorListItem
                      }}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {loanSelectionError.isOrgSelected && (
                  <FormHelperText className={classes.selectionErrorText}>
                    Selection is required.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {isLATUser() && (
            <Grid item xs={12} className={classes.originatorDropDownContainer}>
              <FormControl
                data-testid="originator-form-control"
                fullWidth
                error={loanSelectionError.isOrgSelected}
              >
                <Select
                  inputProps={{
                    "data-testid": "originator-select"
                  }}
                  disabled={isLoading}
                  value={currentOriginatorId}
                  onChange={onOriginatorValueChange as any}
                  variant="standard"
                  displayEmpty
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: 0
                    },
                    transformOrigin: {
                      vertical: -8,
                      horizontal: "left"
                    }
                    // getContentAnchorEl: null
                    // variant="standard"
                  }}
                  classes={{
                    root: classes.originatorSelectRoot,
                    icon: classes.originatorIcon
                  }}
                >
                  <MenuItem
                    value=""
                    disabled
                    classes={{
                      root: classes.originatorListItem
                    }}
                  >
                    Select Originator
                  </MenuItem>
                  {originatorList?.map((item: any) => (
                    <MenuItem
                      value={item?.partyId}
                      key={item?.partyId}
                      classes={{
                        root: classes.originatorListItem
                      }}
                    >
                      {item?.accountName}
                    </MenuItem>
                  ))}
                </Select>
                {loanSelectionError.isOrgSelected && (
                  <FormHelperText className={classes.selectionErrorText}>
                    Selection is required.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          
          <Grid
            style={{ marginTop: "25px", paddingLeft: "25px", width: "100%"}}
          >
            <ToggleGroup
              toggleOptions={loanTypeOptions}
              handleChange={handleLoanTypeToggle}
              alignment={loanType?.loanTypeName ?? ""}
              classes={classes}
              groupLabel="Select Loan Type"
            />
          </Grid>
          {loanSelectionError.loanTypeError && (
            <Grid
              xs={12}
              className={classes.errorLabel}
              style={{ paddingTop: 12 }}
            >
              <span className={classes.selectionErrorText}>
                Selection is required.
              </span>
            </Grid>
          )}
          <RadioButtonGroup
            radioGroupTitle="Select Loan Stage"
            classes={classes}
            onChange={handleRadioChange}
            selectedValue={loanStage}
            radioList={loanStageValues}
            itemColSize={
              /* @ts-ignore */
              loanStageValues.filter(({ isHidden }) => !isHidden).length >= 4
                ? 6
                : 4
            }
          />

          {loanSelectionError.loanStageError && (
            <Grid xs={12} className={classes.errorLabel}>
              <span className={classes.selectionErrorText}>
                Selection is required.
              </span>
            </Grid>
          )}
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            {docUploaded ? (
              <div className={classes.uploadContainer}>
                <Container maxWidth="xs">
                  <div>
                    <div className={classes.fileIconContainer}>
                      <img src={excelIcon} alt="excel file" />
                      <Typography
                        data-testid="format"
                        className={classes.centered}
                      >
                        {getFileType(uploadedDocName)}
                      </Typography>
                    </div>
                    <Tooltip
                      title={
                        uploadedDocName && uploadedDocName.length > 50
                          ? uploadedDocName
                          : ""
                      }
                    >
                      <Typography
                        data-testid="uploaded-doc-name"
                        className={classes.fileName}
                      >
                        {uploadedDocName && uploadedDocName.length > 50
                          ? `${uploadedDocName.slice(0, 50)}...`
                          : uploadedDocName}
                      </Typography>
                    </Tooltip>
                  </div>
                </Container>
              </div>
            ) : (
              <>
                {uploading ? (
                  <div className={classes.uploadContainer}>
                    <Container maxWidth="xs">
                      <div className={classes.uploadExcelLoading}>
                        <CircularProgress size={50} />
                      </div>
                    </Container>
                  </div>
                ) : (
                  <DragAndDrop
                    data={data}
                    dispatch={dispatchh}
                    loanStage={loanStage}
                    loanType={loanType}
                    validateSelections={validateSelections}
                  >
                    <div className={classes.dragDropContainer}>
                      <Container maxWidth="xs">
                        <Typography
                          data-testid="upload-text"
                          className={classes.uploadText}
                        >
                          Select {isLATUser() && "Originator,"}Loan Type and
                          Loan Stage to drag and drop the loan file here if you
                          already have the loan data in an excel format.
                          <br />
                          <br /> Else, click the button below to upload.
                        </Typography>
                      </Container>
                    </div>
                  </DragAndDrop>
                )}
              </>
            )}
            {/* </Container>
            </div> */}
          </Grid>
          {errorInDoc && !duplicateLoanErrorInDoc && (
            <Typography className={classes.errorText}> {errorInDoc}</Typography>
          )}
          {conflictError && (
            <Typography className={classes.errorText}>
              There is already a ToorVal Loan in the system with same property
              Address and or Primary Loan ID! You may edit the existing ToorVal{" "}
              <a
                href={`/loan/createLoan/BridgeLoan/TOORVAL/${conflictError.loanId}`}
              >
                here
              </a>
              , or upload a data tape for a different property and loan ID.{" "}
            </Typography>
          )}
          <Box className={classes.bottomButtonWrapper}>
            {!docUploaded && (
              <BrowseFileButton
                text="Upload Excel"
                startIcon={<UploadExcelIcon />}
                disabled={isBulkUplodDisabled}
                testId="upload-button"
                onChange={(e: any) => {
                  if (validateSelections()) {
                    handleUpload(e.target.files);
                  }
                }}
                dontAllowMultipleSelect
              />
            )}
            {docUploaded && (
              <Grid>
                <Button
                  variant="contained"
                  data-testid="create-loan-excel-button"
                  color="primary"
                  startIcon={<img src={remove} alt="manual_create_loan" />}
                  onClick={handleDocRemove}
                  disabled={uploading}
                >
                  Remove
                </Button>
                <Button
                  variant="contained"
                  data-testid="create-loan-excel-button"
                  color="info"
                  startIcon={<img src={proceed} alt="manual_create_loan" />}
                  onClick={handleProceedClick}
                  disabled={!!errorInDoc || uploading}
                >
                  {uploading && <CircularProgress size={14} />}
                  Proceed
                </Button>
              </Grid>
            )}
            <Box my={2}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#7a8aa0"
                }}
              >
                or
              </Typography>
            </Box>
            <Grid>
              <Button
                variant="contained"
                data-testid="create-manually-button"
                color="primary"
                startIcon={<ManualCreateLoanIcon loanStage={loanStage} />}
                onClick={createLoan}
                disabled={isManualCreateDisabled}
              >
                {loading && <CircularProgress size={14} />}
                {loanStage === "Guideline Evaluation"
                  ? "Evaluate Loan"
                  : "Manually Create the Loan"}
              </Button>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Grid
        container
        style={{
          minHeight: "140px",
          paddingTop: "10px",
          marginBottom: "-14px",
          position: "relative",
          zIndex: 0
        }}
        alignItems="baseline"
        className="illustration-img"
      >
        {showWarning.isOpen && (
          <WarningModal
            isOpen={showWarning.isOpen}
            handleClose={handleWarningClose}
            header={showWarning.header || "Warning"}
            body={showWarning.body || ""}
            primaryBtnName={showWarning.primaryButtonName}
            handleOptional={
              !showWarning.secondaryButtonRequired
                ? showWarning.secondaryButtonRequired
                : handleWarningOptional
            }
          />
        )}
        <Grid
          item
          xs={1}
          sm={6}
          md={6}
          lg={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            height: "100%"
          }}
        >
          <img
            src={loanImgLeft}
            alt="left_img_trades"
            style={{ maxWidth: "267px", width: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={11}
          sm={6}
          md={6}
          lg={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            src={loanImgRight}
            alt="right_img_trades"
            style={{ paddingBottom: "5px", height: "117px" }}
          />
        </Grid>
      </Grid>
      <ResolutionNotSupported />
    </div>
  );
};
