import React from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  typeCast,
  typeCastOnBlur
} from "../../../create-loan/tape-to-file/TapeToFileUtils";
// import { updateDrawAmountDetails } from "../../../stores/drawRequest/drawRequest.action";
import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { RootState } from "../../../stores/rootReducer";
import { cleanCurrency } from "../../../utils/formatChecks";
import { DrawViewModeTextField } from "../DrawViewModeTextField";
import { useDrawsStyles } from "../Draws.styles";
import { DrawStage } from "@toorak/tc-common-fe-sdk";
import { updateDrawObj } from "../../../stores/drawRequest/drawRequest.reducer";

export const calculateNetAmount = (drawAmountDetails: any) => {
  const drawAmount: any = drawAmountDetails?.drawAmount
    ? cleanCurrency(drawAmountDetails.drawAmount)
    : 0;
  const wireFee: any = drawAmountDetails?.wireFee
    ? cleanCurrency(drawAmountDetails.wireFee)
    : 0;
  const costOfInspection: any = drawAmountDetails?.costOfInspection
    ? cleanCurrency(drawAmountDetails.costOfInspection)
    : 0;
  const titleServiceFee: any = drawAmountDetails?.titleServiceFee
    ? cleanCurrency(drawAmountDetails.titleServiceFee)
    : 0;
  const wireReturnFee: any = drawAmountDetails?.wireReturnFee
    ? cleanCurrency(drawAmountDetails.wireReturnFee)
    : 0;
  const netFunds: number =
    drawAmount - wireFee - costOfInspection - titleServiceFee - wireReturnFee;
  return netFunds && netFunds > 0
    ? typeCastOnBlur(netFunds.toString(), "currency", "")
    : 0;
};

export const DrawAmountDetails = ({
  drawObj,
  isAmounteditable,
  selectedLoanId,
  isSubmitClicked,
  showErrorMsg,
  fieldWidth
}: // showErrorMsg
any) => {
  const classes = useDrawsStyles();
  const dispatch = useDispatch();
  // const [isMinimized, setIsMinimized] = useState<boolean>(false);

  const { drawDetails, fieldsHistory, drawStageHistory } = useSelector<
    RootState,
    DrawRequestStore
  >((state) => state.drawRequestStore);

  const { WireUnsuccessful } = DrawStage;

  const handleDrawAmountChange = (val: any, key: string) => {
    const formatterVal = typeCast(val, "currency");
    let drawDetailsCopy = JSON.parse(JSON.stringify(drawDetails));
    drawDetailsCopy.drawAmountDetails[key] = formatterVal;
    drawDetailsCopy["isManual"] = true;
    dispatch(updateDrawObj(drawDetailsCopy));
  };

  const handleDrawAmountBlur = (val: any, key: string) => {
    const formatterVal = typeCastOnBlur(val, "currency", "");
    let drawObjCopy = JSON.parse(JSON.stringify(drawObj));
    drawObjCopy.drawAmountDetails[key] = formatterVal;
    const netFundsToBorrower = calculateNetAmount(
      drawObjCopy.drawAmountDetails
    );
    drawObjCopy.drawAmountDetails.netFundsToBorrower = netFundsToBorrower;
    drawObjCopy["isManual"] = true;
    setTimeout(() => {
      dispatch(updateDrawObj(drawObjCopy));
    }, 200);
  };
  const doesHistoryHasWireUnsuccessful = () =>
    !!drawStageHistory.filter((it: any) => it.stageName === WireUnsuccessful)
      .length;

  return (
    <Grid item container xs={12} className={classes.itemContainer}>
      <Grid item container xs={10} className={classes.itemHeaderText}>
        Draw Amount Details
      </Grid>

      <Grid item container xs={12} className={classes.fieldContainer}>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          {isAmounteditable() ? (
            <Grid>
              <p
                className={`${classes.headerLoanID} ${
                  selectedLoanId === ""
                    ? classes.disabledHeaderText
                    : classes.headerLoanID
                }`}
              >
                Draw Amount($)
                <span
                  className={`${classes.asterick} ${
                    selectedLoanId === ""
                      ? classes.hiddenAsterick
                      : classes.asterick
                  }`}
                >
                  *
                </span>
              </p>

              <input
                disabled={selectedLoanId === ""}
                value={drawObj?.drawAmountDetails?.drawAmount}
                placeholder={"Enter number"}
                data-testid="toorak-draw-amount"
                onChange={(e: any) =>
                  handleDrawAmountChange(e.target.value, "drawAmount")
                }
                onBlur={(e: any) =>
                  handleDrawAmountBlur(e.target.value, "drawAmount")
                }
                className={classes.inputValue}
              />
              {isSubmitClicked ? showErrorMsg("drawAmount") : null}
            </Grid>
          ) : (
            <DrawViewModeTextField
              label={"Draw Amount($)"}
              value={drawObj?.drawAmountDetails?.drawAmount}
              type={"currency"}
              editedData={fieldsHistory.drawAmount}
            />
          )}
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          {isAmounteditable() ? (
            <Grid>
              <p
                className={`${classes.headerLoanID} ${
                  selectedLoanId === ""
                    ? classes.disabledHeaderText
                    : classes.headerLoanID
                }`}
              >
                Toorak Draw Fee($)
                <span
                  className={`${classes.asterick} ${
                    selectedLoanId === ""
                      ? classes.hiddenAsterick
                      : classes.asterick
                  }`}
                >
                  *
                </span>
              </p>
              <input
                disabled={selectedLoanId === ""}
                value={drawObj?.drawAmountDetails?.wireFee}
                data-testid="draw-wire-fee"
                onChange={(e: any) =>
                  handleDrawAmountChange(e.target.value, "wireFee")
                }
                onBlur={(e: any) =>
                  handleDrawAmountBlur(e.target.value, "wireFee")
                }
                className={classes.inputValue}
              />
              {isSubmitClicked ? showErrorMsg("wireFee") : null}
            </Grid>
          ) : (
            <DrawViewModeTextField
              label={"Toorak Draw Fee($)"}
              value={drawObj?.drawAmountDetails?.wireFee}
              type={"currency"}
              editedData={fieldsHistory.wireFee}
            />
          )}
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          {isAmounteditable() ? (
            <Grid>
              <p
                className={`${classes.headerLoanID} ${
                  selectedLoanId === ""
                    ? classes.disabledHeaderText
                    : classes.headerLoanID
                }`}
              >
                Cost Of Inspection($)
                <span
                  className={`${classes.asterick} ${
                    selectedLoanId === ""
                      ? classes.hiddenAsterick
                      : classes.asterick
                  }`}
                >
                  *
                </span>
              </p>
              <input
                disabled={selectedLoanId === ""}
                value={drawObj?.drawAmountDetails?.costOfInspection}
                data-testid="toorak-costOfInspection"
                onChange={(e: any) =>
                  handleDrawAmountChange(e.target.value, "costOfInspection")
                }
                onBlur={(e: any) =>
                  handleDrawAmountBlur(e.target.value, "costOfInspection")
                }
                className={classes.inputValue}
              />
              {isSubmitClicked ? showErrorMsg("costOfInspection") : null}
            </Grid>
          ) : (
            <DrawViewModeTextField
              label={"Cost Of Inspection($)"}
              value={drawObj?.drawAmountDetails?.costOfInspection}
              type={"currency"}
              editedData={fieldsHistory.costOfInspection}
            />
          )}
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          {isAmounteditable() ? (
            <Grid>
              <p
                className={`${classes.headerLoanID} ${
                  selectedLoanId === ""
                    ? classes.disabledHeaderText
                    : classes.headerLoanID
                }`}
              >
                Title Service Fee($)
                <span
                  className={`${classes.asterick} ${
                    selectedLoanId === ""
                      ? classes.hiddenAsterick
                      : classes.asterick
                  }`}
                >
                  *
                </span>
              </p>
              <input
                disabled={selectedLoanId === ""}
                value={drawObj?.drawAmountDetails?.titleServiceFee}
                data-testid="toorak-titleServiceFee"
                onChange={(e: any) =>
                  handleDrawAmountChange(e.target.value, "titleServiceFee")
                }
                onBlur={(e: any) =>
                  handleDrawAmountBlur(e.target.value, "titleServiceFee")
                }
                className={classes.inputValue}
              />
              {isSubmitClicked ? showErrorMsg("titleServiceFee") : null}
            </Grid>
          ) : (
            <DrawViewModeTextField
              label={"Title Service Fee($)"}
              value={drawObj.drawAmountDetails?.titleServiceFee}
              type={"currency"}
              editedData={fieldsHistory.titleServiceFee}
            />
          )}
        </Grid>
        {doesHistoryHasWireUnsuccessful() && (
          <Grid item container xs={fieldWidth} className={classes.fieldRow}>
            {isAmounteditable() ? (
              <Grid>
                <p
                  className={`${classes.headerLoanID} ${
                    selectedLoanId === ""
                      ? classes.disabledHeaderText
                      : classes.headerLoanID
                  }`}
                >
                  Wire Return Fee($)
                  <span
                    className={`${classes.asterick} ${
                      selectedLoanId === ""
                        ? classes.hiddenAsterick
                        : classes.asterick
                    }`}
                  >
                    *
                  </span>
                </p>
                <input
                  disabled={selectedLoanId === ""}
                  value={drawObj?.drawAmountDetails?.wireReturnFee}
                  data-testid="toorak-wireReturnFee"
                  onChange={(e: any) =>
                    handleDrawAmountChange(e.target.value, "wireReturnFee")
                  }
                  onBlur={(e: any) =>
                    handleDrawAmountBlur(e.target.value, "wireReturnFee")
                  }
                  className={classes.inputValue}
                />
                {isSubmitClicked ? showErrorMsg("wireReturnFee") : null}
              </Grid>
            ) : (
              <DrawViewModeTextField
                label={"Wire Return Fee($)"}
                value={drawObj?.drawAmountDetails?.wireReturnFee}
                type={"currency"}
                editedData={fieldsHistory.wireReturnFee}
              />
            )}
          </Grid>
        )}
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <>
            <DrawViewModeTextField
              label={"Net Funds To Borrower($)"}
              value={drawObj?.drawAmountDetails?.netFundsToBorrower}
              type={"currency"}
              editedData={fieldsHistory.netFundsToBorrower}
            />
            {isSubmitClicked ? showErrorMsg("netFundsToBorrower") : null}
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};
