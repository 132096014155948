/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable */

import { DATE_TIME_UTILS, LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { getLoanType } from "../../src/config/config";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { AssetManagementRolesEnum, postApprovalStates } from "./constants";
import { isDrawAMRole, isLATUser, isPrivilege, isRole } from "./AccessManagement";
import moment from "moment-timezone";
import {formatValueToUIByType} from "./formatChecks";
// const { formatValueByType } = require("./formatChecks");
// const {
//   cleanDecimal
// } = require("../create-loan/30-year-loan-details/ValidationRule");
/**
 * @this throttle
 */
export function throttle(func, wait) {
  let context;
  let args;
  let result;
  let timeout = null;
  let previous = 0;
  const options = {};
  const later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    const now = Date.now();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}
/* eslint-enable */

/* eslint-disable */
export function uuidV4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function constructName(firstName, middleName, lastName) {
  let nameArray = [firstName, middleName, lastName];
  nameArray = nameArray.filter(Boolean);
  return nameArray.join(" ");
}

export function titleCase(txt) {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

export function hideCustodyDoc() {
  // const prodEnv = isRole("ORG_CUSTODY_DOCS");
  // const prodEnv = window.location.hostname === "www.toorakcapital.info";
  return false;
}

export function isAnyLATAMRole() {
  return (isPrivilege("view_asset_management") ||
  isDrawAMRole() ||
  isRole(AssetManagementRolesEnum.AM_SO))
}

export function groupByMonth(data) {
  let seriesData = { data: [], categories: [] };
  data.map((element, index) => {
    seriesData["data"].push(element["amount"]["value"]);
    seriesData["categories"].push(element["key_as_string"]);
  });
  return seriesData;
}
export function groupByValue(data, value) {
  let seriesData = { data: [], categories: [] };
  data.map((element, index) => {
    if (value === "Number") {
      seriesData["data"].push(element["doc_count"]);
    } else {
      seriesData["data"].push(element["amount"]["value"]);
    }
    seriesData["categories"].push(element["key_as_string"]);
  });
  return seriesData;
}
export function groupByLoan(data) {
  let seriesData = { data: [], categories: [] };
  data.map((element, index) => {
    seriesData["data"].push(element["amount"]["value"]);
    if (element["key"].includes("30 Year")) {
      seriesData["categories"].push("Investor DSCR");
    } else {
      seriesData["categories"].push(element["key"]);
    }
  });
  if (seriesData["data"].filter(item => item !== 0).length <= 0) {
    seriesData = { data: [], categories: [] };
  }

  return seriesData;
}

export function getUnReadNot(data) {
  return data instanceof Array
    ? data.filter(item => item?.notificationstatus?.status !== "READ")
    : [];
}
export function groupByCategory(data, type) {
  return data instanceof Array
    ? data.filter(item => item._source.resource.toLowerCase() === type)
    : [];
}

export function getMultiFetchQuery(data) {
  let obj = [];
  data.forEach(item => {
    if (item._source.resource.toLowerCase() === "exception") {
      let exception = {
        parentId: item?._source?.loanId,
        resourceKey: "EXCEPTION",
        resourceValue: item?._source?.exception?.exceptionId
      };
      obj.push(exception);
    }
    if (item._source.resource.toLowerCase() === "waiver") {
      let waiver = {
        parentId: item?._source?.loanId,
        resourceKey: "WAIVER",
        resourceValue: item?._source?.waiver?.waiverId
      };
      obj.push(waiver);
    }
  });
  return obj;
}

export function getChatIds(data) {
  let result = data.map(item => {
    if (item._source.resource.toLowerCase() === "discussion") {
      return item?._source?.discussion?.id;
    }
  });
  return result;
}

export function getUnReadNotCount(data) {
  const usersByResouce = data.reduce((acc, value) => {
    // Group initialization
    if (!acc[value.resource.resourceValue]) {
      acc[value.resource.resourceValue] = [];
    }
    // Grouping
    acc[value.resource.resourceValue].push(value);

    return acc;
  }, {});
  return usersByResouce;
}

export function groupByDefaultData(data) {
  let defaultSeriesData = {
    defaultData: [0, 0, 0, 0],
    numberOfLoans: [0, 0, 0, 0],
    preData: [],
    postData: [],
    fesData: [],
    psData: [],
    totalData: 0,
    totalLoans: 0
  };
  data.map((element, index) => {
    if (element["key"] === "PRE") {
      let preData;
      preData = getLoanData("preData", defaultSeriesData, element);
      defaultSeriesData = preData.defaultSeriesData;
      defaultSeriesData["defaultData"][1] = preData.total;
      defaultSeriesData["numberOfLoans"][1] = preData.doc_count;
    } else if (element["key"] === "POST") {
      let postData;
      postData = getLoanData("postData", defaultSeriesData, element);
      defaultSeriesData = postData.defaultSeriesData;
      defaultSeriesData["defaultData"][2] = postData.total;
      defaultSeriesData["numberOfLoans"][2] = postData.doc_count;
      let psData;
      psData = getLoanData("psData", defaultSeriesData, element);
      defaultSeriesData = psData.defaultSeriesData;
      defaultSeriesData["defaultData"][3] = psData.total;
      defaultSeriesData["numberOfLoans"][3] = psData.doc_count;
    } else if (element["key"] === "FES") {
      let fesData;
      fesData = getLoanData("fesData", defaultSeriesData, element);
      defaultSeriesData = fesData.defaultSeriesData;
      defaultSeriesData["defaultData"][0] = fesData.total;
      defaultSeriesData["numberOfLoans"][0] = fesData.doc_count;
    }
    defaultSeriesData["totalData"] = convertToMillions(
      defaultSeriesData["defaultData"].reduce((a, b) => a + b, 0)
    );
    defaultSeriesData["totalLoans"] = defaultSeriesData["numberOfLoans"].reduce(
      (a, b) => a + b,
      0
    );
  });
  return defaultSeriesData;
}

export function getLoanData(type, defaultSeriesData, element) {
  const {
    DataEntry,
    InitialReview,
    DueDiligenceReview,
    FinalReview,
    Approved,
    ExcelError,
    Submit
  } = LoanStatusEnum;
  let prePostLoanParameter = [
    DataEntry,
    InitialReview,
    DueDiligenceReview,
    FinalReview,
    Approved,
    ExcelError,
    Submit
  ];
  let fesLoanParameter = [
    DataEntry,
    InitialReview,
    FinalReview,
    Approved,
    ExcelError,
    Submit
  ];
  const prePostRadius =
    screen.width < 1500 ? [150, 135, 95, 85, 75] : [150, 135, 120, 110, 100];
  let total = 0;
  let doc_count = 0;
  let parameters = type === "fesData" ? fesLoanParameter : prePostLoanParameter;
  if (type === "psData") {
    parameters = postApprovalStates;
  }
  defaultSeriesData[`${type}`] = element["amountPerState"]["buckets"].filter(
    item => parameters.includes(item.key)
  );
  defaultSeriesData[`${type}`] =
    type === "psData"
      ? defaultSeriesData[`${type}`]
      : getDefaultSeriesData(defaultSeriesData[`${type}`]);
  defaultSeriesData[`${type}`].sort((a, b) => b.amount.value - a.amount.value);
  defaultSeriesData[`${type}`] = defaultSeriesData[`${type}`].map(
    (ele, ind) => {
      total = total + parseInt(ele.amount.value);
      doc_count = doc_count + ele.doc_count;
      ele.amount.value = convertToMillions(ele.amount.value);
      ele.width = prePostRadius[ind];
      return ele;
    }
  );
  var sortedArray = [];
  for (let i = 0; i < defaultSeriesData[`${type}`].length; i++) {
    let j = defaultSeriesData[`${type}`].length - 1;
    if (parameters[i] === defaultSeriesData[`${type}`][i].key) {
      if (defaultSeriesData[`${type}`][i].key === "Purchased" && !isLATUser()) {
        defaultSeriesData[`${type}`][i].key = "Funded";
        sortedArray.push(defaultSeriesData[`${type}`][i]);
      } else {
        sortedArray.push(defaultSeriesData[`${type}`][i]);
      }
    } else {
      while (j >= 0) {
        if (parameters[i] === defaultSeriesData[`${type}`][j].key) {
          if (
            defaultSeriesData[`${type}`][j].key === "Purchased" &&
            !isLATUser()
          ) {
            defaultSeriesData[`${type}`][j].key = "Funded";
            sortedArray.push(defaultSeriesData[`${type}`][j]);
          } else {
            sortedArray.push(defaultSeriesData[`${type}`][j]);
          }
        }
        j--;
      }
    }
  }
  defaultSeriesData[`${type}`] = sortedArray;
  return {
    defaultSeriesData: defaultSeriesData,
    total: total,
    doc_count: doc_count
  };
}

export function getDefaultSeriesData(defaultSeriesData) {
  let dataEntrySum = {
    amount: { value: 0 },
    doc_count: 0,
    key: ""
  };

  const defaultData = defaultSeriesData.filter(
    item =>
      item.key !== "Data Entry" &&
      item.key !== "submit" &&
      item.key !== "Excel Error"
  );
  defaultSeriesData.forEach((item, index) => {
    if (
      item.key === "Data Entry" ||
      item.key === "submit" ||
      item.key === "Excel Error"
    ) {
      dataEntrySum.amount.value = dataEntrySum.amount.value + item.amount.value;
      dataEntrySum.doc_count = dataEntrySum.doc_count + item.doc_count;
      dataEntrySum.key = "Data Entry";
    }
  });
  defaultData.unshift(dataEntrySum);
  return defaultData;
}
/* eslint-enable */
export function formatDate(date) {
  try {
    if (date) {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      if (typeof date === "string" || typeof date === "number") {
        const newDate = new Date(date);
        if (!isNaN(newDate.getTime())) {
          return `${month[newDate.getMonth()]} ${`0${newDate.getDate()}`.slice(
            -2
          )}, ${newDate.getFullYear()}`;
        }
        return "";
      }
      if (!isNaN(date.getTime())) {
        return `${month[date.getMonth()]} ${`0${date.getDate()}`.slice(
          -2
        )}, ${date.getFullYear()}`;
      }
    }
  } catch (error) {
    return "";
  }
}
export function changeDateFormat(date) {
  try {
    if (date) {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      // const nth = function(d) {
      //   if (d > 3 && d < 21) return "th";
      //   switch (d % 10) {
      //     case 1:
      //       return "st";
      //     case 2:
      //       return "nd";
      //     case 3:
      //       return "rd";
      //     default:
      //       return "th";
      //   }
      // };
      if (typeof date === "string") {
        const newDate = new Date(date);
        if (!isNaN(newDate.getTime())) {
          // return `${newDate.getDate()}${nth(newDate.getDate())} ${
          return `${month[newDate.getMonth()]} ${`0${newDate.getDate()}`.slice(
            -2
          )}, ${newDate.getFullYear()}`;
        }
        return "";
      }
      if (!isNaN(date.getTime())) {
        // return `${date.getDate()}${nth(date.getDate())} ${
        return `${month[date.getMonth()]} ${`0${date.getDate()}`.slice(
          -2
        )}, ${date.getFullYear()}`;
      }
    }
  } catch (error) {
    return "";
  }
}

export function getDateInFormat(date) {
  if (
    date &&
    typeof date === "string" &&
    date.charAt(date.length - 1) !== "Z" &&
    !date.includes("+00:00")
  ) {
    date += "Z";
  }
  let newDate;
  if (typeof date === "string" || typeof date === "number") {
    newDate = new Date(date);
  } else {
    newDate = date;
  }
  const index = newDate && newDate.toString().indexOf("T");
  const createdDate = index !== 10 ? new Date(newDate) : newDate;
  const dateArr = createdDate ? createdDate.toString().split(" ", 8) : "";
  dateArr[4] = dateArr[4] && dateArr[4].slice(0, dateArr[4].lastIndexOf(":"));
  return dateArr
    ? `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]}, ${dateArr[4]} `
    : "";
}

export function getNotificationDate() {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() - 7);
  const month = newDate.getMonth() + 1;
  const index = newDate && newDate.toString().indexOf("T");
  const createdDate = index !== 10 ? new Date(newDate) : newDate;
  const dateArr = createdDate ? createdDate.toString().split(" ", 8) : "";
  return dateArr ? `${dateArr[3]}-${month}-${dateArr[2]}T${dateArr[4]}` : "";
}

export function defaultDateFormat(date) {
  try {
    if (!date) return "";
    let nDate = date;
    if (typeof nDate === "number") {
      nDate = moment(new Date(nDate));
    } else if (typeof nDate === "string") {
      nDate = String(nDate).split("T")[0];
    }
    // const newDate = moment.utc(nDate).format("MM/DD/YYYY");
    // console.log(date, "date", nDate, moment.utc(nDate).format("MM/DD/YYYY"), "fDate", "initRows");
    return moment.utc(nDate).format("MM/DD/YYYY");
  } catch (err) {
    return "";
  }
}

export function getTimeFromDate(date, format) {
  try {
    if (!date) return "";
    return moment(date).format(format);
  } catch (e) {
    return "";
  }
}

export function bundleCardDateFormat(date) {
  try {
    if (!date) return "";
    let nDate = date;
    if (typeof nDate === "number") {
      nDate = moment(new Date(nDate));
    } else if (typeof nDate === "string") {
      nDate = String(nDate).split("T")[0];
    }
    return moment.utc(nDate).format("DD MMM YYYY");
  } catch (err) {
    return "";
  }
}

export function getALlOriginatorIds(data) {
  const orgIds = [];
  data.forEach(element => {
    orgIds.push(element?.key);
  });
  return orgIds;
}
export function convertToMillions(num) {
  let number;
  if (typeof num === "string") {
    num = num.replaceAll(",", "");
    num = Number(num);
  }
  if (num < 1000000) {
    number = `$${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  } else if (num >= 1000000 && num < 1000000000) {
    number = (num / 1000000).toFixed(2);
    number = `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
  } else if (num >= 1000000000 && num < 1000000000000) {
    number = (num / 1000000000).toFixed(2);
    number = `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}B`;
  } else if (num >= 1000000000000 && num < 1000000000000000) {
    number = (num / 1000000000000).toFixed(2);
    number = `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}T`;
  } else if (num >= 1000000000000000 && num < 1000000000000000000) {
    number = (num / 1000000000000000).toFixed(2);
    number = `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}QD`;
  } else {
    number = (num / 1000000000000000000).toFixed(2);
    number = `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}QT`;
  }
  return number;
}

export function returnNumberValue(num) {
  return num.toFixed(2);
}
export function groupByUserNames(user) {
  const userData = [];
  user.forEach((element, index) => {
    if (element.accountType && element.accountType === "ORIGINATOR") {
      userData[index] = {
        accountName: element.accountName,
        partyId: element.partyId
      };
    }
  });
  return userData;
}
export function getALlOriginatorIdsLoanPurchased(data) {
  const orgIds = [];
  data.forEach((element, index) => {
    if (orgIds.indexOf(element?.key) === -1) {
      orgIds.push(element?.key);
    }
  });
  return orgIds;
}

export function yyyymmdd(date) {
  const d = moment(new Date(date), "YYYY-MM-DD").toDate();
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = d.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[year, month, day].join("-")}T00:00`;
}

export function convertToLocalDate(date, dateFormat = "MM/DD/YYYY") {
  // console.log(date)
  // const d = moment.utc(`${date} 00:00:00`, "MM/DD/YYYY hh:mm:ss").toDate();
  // const d = moment.utc(`${date}`, "MM/DD/YYYY").toDate();
  const d = moment.utc(`${date}`, dateFormat).toDate();
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(d.getTime() + tzoffset);
  // console.log(localISOTime)
  // console.log(a)
  // const c = new Date(a)
  // console.log(date, d, a, "choosen");
  return moment.utc(localISOTime).toDate();
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};

export const isString = value => {
  return typeof value === "string" || value instanceof String;
};

export function MMMDDYYYYDateFormat(
  date,
  inputFormat = "YYYY-MM-DD",
  outputFormat = "MMM DD, YYYY"
) {
  try {
    if (!date) return "";
    let nDate = date;
    if (typeof nDate === "number") {
      nDate = moment(new Date(nDate));
    } else if (typeof nDate === "string") {
      nDate = String(nDate).split("T")[0];
    }
    return moment.utc(nDate, inputFormat).format(outputFormat);
  } catch (err) {
    return "";
  }
};

export function detectBrowser() {
  if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "Edge";
  }
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  }
  if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  }
  if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  }
  if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  }

  return "Unknown";
}

export function toggleBlurEffectFireFox(apply) {
  if (apply) {
    document.getElementById("root").style.filter = "blur(3px)";
  } else {
    document.getElementById("root").style.filter = "none";
  }
}

export function checkIfValidDate(d) {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(d.getTime())) {
      return false;
    }
    return true;
  }
  return false;
}

export function resolvePath(path, obj, variables = {}) {
  // Remove keys with falsy values from the variables object
  const filteredVariables = Object.fromEntries(
    Object.entries(variables).filter(([key, value]) => Boolean(value))
  );

  // Replace dynamic variables in the path
  const modifiedPath = path.replace(/\[(\w+)\]/g, (_, varName) => {
    return filteredVariables[varName] !== undefined ? filteredVariables[varName] : `[${varName}]`;
  });

  return modifiedPath.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
}

export function getDiffTime(data) {
  const oldDate = new Date(data);
  const currentDate = new Date();
  const msec = currentDate - oldDate;
  const secs = Math.floor(msec / 1000);
  const mins = Math.floor(msec / 60000);
  const hrs = Math.floor(mins / 60);
  const days = Math.floor(hrs / 24);
  return { secs, mins: mins % 60, hrs: hrs % 24, days: days % 365 };
}

export function generateAgeingLabel(value) {
  const timeObj = getDiffTime(value);
  let labelText = null;
  if (timeObj.days === 0 && timeObj.hrs === 0 && timeObj.mins === 0) {
    const secText = timeObj.secs < 10 ? "second" : "seconds";
    labelText = `${timeObj.secs} ${secText} ago`;
  } else if (timeObj.days === 0 && timeObj.hrs === 0) {
    const minText = timeObj.mins === 1 ? "minute" : "minutes";
    labelText = `${timeObj.mins} ${minText} ago`;
  } else if (timeObj.days === 0 && timeObj.hrs > 0) {
    const hourText = timeObj.hrs === 1 ? "hour" : "hours";
    labelText = `${timeObj.hrs} ${hourText} ago`;
  } else {
    const dayText = timeObj.days === 1 ? "DAY" : "DAYS";
    labelText = `${timeObj.days} ${dayText}`;
  }
  return `${labelText}`;
}
export function getTwoDecimalPercentageValue(data) {
  if (data && typeof data === "string") {
    const temp = data.slice(0, data.length - 1);
    return `${Number(temp).toFixed(2)}%`;
  }
  return data;
}

export function getLatestWaiverResponse(waiverResponses) {
  return waiverResponses
    .slice()
    .sort((a, b) => Date.parse(a.responseDate) - Date.parse(b.responseDate));
}

export function getAvailableRuleVersions(availableVersions) {
  let versionArray = [];
  if (availableVersions.length) {
    versionArray = [...availableVersions];
    versionArray = versionArray.sort(function (a, b) {
      const dateA = new Date(a.effectiveStartDate);
      const dateB = new Date(b.effectiveStartDate);
      return dateB.valueOf() - dateA.valueOf();
    });
  }
  return versionArray;
}

export function setIntervalX(callback, delay, repetitions) {
  let x = 0;
  var intervalID = window.setInterval(function () {
    callback();
    if (++x === repetitions) {
      window.clearInterval(intervalID);
    }
  }, delay);
}

export function formatDateAudit(
  auditArr,
  inputFormat = "YYYY-MM-DD",
  outputFormat = "MMM DD, YYYY"
) {
  try {
    return auditArr.map(auditObj => {
      const { value, preValue } = auditObj;
      const formattedValue = MMMDDYYYYDateFormat(
        value,
        inputFormat,
        outputFormat
      );
      const formattedPrevValue = MMMDDYYYYDateFormat(
        preValue,
        inputFormat,
        outputFormat
      );
      return {
        ...auditObj,
        value: formattedValue !== "Invalid date" ? formattedValue : value,
        preValue:
          formattedPrevValue !== "Invalid date" ? formattedPrevValue : preValue
      };
    });
  } catch (error) {
    return auditArr;
  }
}

export function hasDuplicateObjects(array) {
  return array
    .map(obj =>
      Object.values(obj).reduce((accumulator, value) => accumulator + value, "")
    )
    .some(
      (value, index, array) => array.indexOf(value) !== array.lastIndexOf(value)
    );
}

export const checkIfToorvalLoan = landmarksData =>
  landmarksData.name === "loan_creation" &&
  landmarksData.stage === LoanStage.tv;

export const checkIfFESLoan = landmarksData =>
  landmarksData.name === "loan_creation" &&
  landmarksData.stage === LoanStage.fes;

export const getLoanTypeDisplayValue = LoanTypeName => {
  switch (LoanTypeName) {
    case getLoanType[0].name:
      return getLoanType[0].displayValue;
    case getLoanType[1].name:
    case getLoanType[1].loanCreationName:
      return getLoanType[1].displayValue;
    case getLoanType[2].name:
      return getLoanType[2].displayValue;
    case getLoanType[3].name:
      return getLoanType[3].displayValue;
    default:
      return getLoanType[0].displayValue;
  }
};
export function toCamelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function checkIfStringStartsWith(arr, str) {
  try {
    return arr.some(substr => str.startsWith(substr));
  } catch {
    return false;
  }
}

export const copyToClipBoard = (e, text) => {
  if (!navigator?.clipboard) return;
  navigator.clipboard.writeText(text);
};

export function limitText(text, len) {
  if (text.length <= len) {
    return text;
  }
  return `${text.slice(0, len)}...`;
}

export function camelCaseToReadableString(camelCaseString) {
  let result = "";
  const letters = [...camelCaseString];

  for (const letter of letters) {
    if (letter === letter.toUpperCase()) {
      result += ` ${letter}`;
    } else {
      result += letter;
    }
  }
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
/**
 *
 * @param {*} targetDate date format new Date(2023, 8, 1);
 * @returns boolean
 */
export function isTodayAfterThisDate(targetDate) {
  const currentDate = new Date();

  return currentDate >= targetDate;
}

export function getOriginatorNameWithPartyId(id, originatorList) {
  const orgObject = originatorList?.filter(eachOrg => {
    return eachOrg.partyId === id;
  });
  return orgObject?.[0]?.accountName ?? "";
}

export const getDate = timestamp => {
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month since it's zero-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  const extractedDate = `${month}/${day}/${year}`;
  return extractedDate;
}

export function isBetweenDesiredRange() {
  const currentDate = moment.tz(DATE_TIME_UTILS?.nyTimeZone);
  // add the timestamps in EST in yyyy-mm-dd hh:mm format

  const startDate = moment.tz("2024-08-28 09:00", DATE_TIME_UTILS?.nyTimeZone);
  const endDate = moment.tz("2024-09-03 09:00", DATE_TIME_UTILS?.nyTimeZone);
  return currentDate.isBetween(startDate, endDate, null, "[)");
}

export function intersection(arrays) {
  if (!arrays || arrays.length === 0) return [];
  const result = arrays.reduce((acc, arr) => {
      return acc.filter(element => arr.includes(element));
  });
  return result;
}

export function getFormattedAuditData(res, fieldPath, fieldType) {
  return {
    noKeysUpdate: true,
        ...res?.data,
      audit: {
      [fieldPath]: res?.data?.audit?.[fieldPath].map((audit) => {
        const data = {
          ...audit,
          preValue: formatValueToUIByType(audit.preValue, fieldType),
          value: formatValueToUIByType(audit.value, fieldType)
        }
        return data;
      })
    }
  }
}